// import { AddThis } from "../components/addthis"
import Layout from "../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"

class StaticPostTemplate extends React.Component {

  render() {
    const { location, data: { file, mdx, site: { siteMetadata } } } = this.props
    // const fullUrl = siteMetadata.siteUrl + location.pathname
    const description = mdx.frontmatter.description || mdx.excerpt
    const image = mdx.frontmatter.image ? mdx.frontmatter.image.childImageSharp.gatsbyImageData : (file ? file.childImageSharp.gatsbyImageData : null)

    return (
      <Layout location={location} title={siteMetadata.title} subtitle={mdx.frontmatter.title} image={image} meta={siteMetadata}>
        <Seo
          title={mdx.frontmatter.title}
          description={description}
          image={image && image.src}
        />
        <article>
          <h1
            style={{
              marginTop: '1em',
            }}
          >
            {mdx.frontmatter.title}
          </h1>
          <MDXRenderer>
            {mdx.body}
          </MDXRenderer>
          {/* <AddThis slug={fullUrl} title={mdx.frontmatter.title} description={description} /> */}
        </article>
        <hr
          style={{
            marginBottom: '1em',
          }}
        />
      </Layout>
    )
  }
}

export default StaticPostTemplate

export const pageQuery = graphql`query StaticPostBySlug($slug: String, $image: String) {
  site {
    siteMetadata {
      title
      siteUrl
      social {
        facebook
      }
    }
  }
  file(relativePath: {eq: $image}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  mdx(fields: {slug: {eq: $slug}}) {
    id
    excerpt
    body
    frontmatter {
      title
      date(formatString: "DD MMM YYYY")
      description
      author
      image {
        childImageSharp {
         gatsbyImageData(layout: FULL_WIDTH)
       }
      }
    }
  }
}
`
